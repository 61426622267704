<script lang="ts">
	const features = [
		{
			name: 'Guides For Sale',
			stats: '20+',
		},
		{
			name: 'People Served Since 2021',
			stats: '6M+',
		},
		{
			name: 'Travelers on Our Email List',
			stats: '105k+',
		},
	]
</script>

<section
	id="secondary-features"
	aria-label="Features for building a portfolio"
	class="pt-20 sm:pt-32 lg:pt-36"
	data-testid="SocialProof"
>
	<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
		<div class="mx-auto max-w-6xl sm:text-center">
			<h2 class="text-3xl lg:text-4xl font-bold tracking-tight text-brand-gray-6">
				Join the Airheart Community
			</h2>
			<p class="mt-3">You're in good company. Here's a snapshot of our community's growth.</p>
			<ul
				role="list"
				class="mx-auto mt-16 grid max-w-6xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-3 md:gap-y-10 lg:max-w-none lg:grid-cols-3"
			>
				{#each features as feature}
					<li class="rounded-md border border-brand-gray-2 p-8">
						<div class="mt-6 font-semibold text-4xl text-brand-gray-6">{feature.stats}</div>
						<div class="mt-6 font-semibold text-brand-gray-6">
							{feature.name}
						</div>
					</li>
				{/each}
			</ul>
		</div>
	</div>
</section>
