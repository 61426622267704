<script lang="ts">
	import Guide from './Guide.svelte'
	import RightArrowIcon from '../icons/RightArrowIcon.svelte'

	const guides = [
		{
			imageUrl: '4d195b25-abaa-4823-4d08-94df2c328201',
			name: 'Mercedes',
			instagram: 'calculatedopulence',
			username: 'Calculatedopulence',
			tiktok: 'calculatedopulence',
		},
		{
			imageUrl: 'ed3c07c2-25c3-4680-27fc-c3380995e900',
			name: 'Payton Cavin',
			instagram: 'mellowyellowpay',
			username: 'mellowyellowpay',
			tiktok: 'mellowyellowpay',
		},
		{
			imageUrl: '7cafcdf0-236c-4c05-076c-902479d39001',
			name: 'Estefania Hernandez',
			instagram: 'findnew_adventures',
			username: 'findnew_adventures',
			tiktok: 'findnew_adventures',
		},
		{
			imageUrl: '46b18f38-681f-42e3-65c2-299644cf9001',
			name: 'Amy Drohen',
			instagram: 'sushigirl_atx',
			username: 'sushigirl_atx',
			tiktok: 'sushigirl_atx',
		},

		{
			imageUrl: '7a239711-80c8-4d5e-a3e7-13b7fa1b9600',
			name: 'Ana',
			instagram: 'thebucketlistlatina',
			username: 'thebucketlistlatina',
			tiktok: 'thebucketlistlatina',
		},
		{
			imageUrl: '6bdaa77b-e5ff-4b41-29bb-11bee44e1701',
			name: 'Cindy N.',
			instagram: 'foreign_fresh_fierce',
			username: 'ForeignFreshFierce',
			tiktok: 'foreignfreshfierce',
		},
		{
			imageUrl: '70baa3fa-3ca0-4980-eb97-0710e7e59a00',
			name: 'Bernice Padilla',
			instagram: 'trendytraveler__',
			username: 'TrendyTraveler',
			tiktok: 'trendytraveler',
		},
		{
			imageUrl: 'c933e2c8-cfde-450b-9139-03bb8f72a700',
			name: 'Tanna Wasilchak',
			instagram: 'tannathetraveler',
			username: 'Tannathetraveler',
			tiktok: 'tannawasilchak',
		},
	]
</script>

<div class="bg-white" data-testid="Guides">
	<div class="max-w-6xl mx-auto pt-12 lg:pt-24 relative isolate">
		<p class="text-center">
			<span class="py-1 text-base font-semibold tracking-wide">Creator Spotlight</span>
		</p>

		<div class="mt-4 grid grid-cols-2 gap-2 md:grid-cols-4 mx-2">
			{#each guides as guide}
				<Guide
					imageUrl={guide.imageUrl}
					name={guide.name}
					username={guide.username}
					instagram={guide.instagram}
					tiktok={guide.tiktok}
				/>
			{/each}
		</div>

		<div
			class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-center mt-8 w-full"
		>
			<a
				href="/guide"
				id="all-popular"
				class="inline-flex items-center space-x-2 font-medium leading-6 text-black group ring-transparent focus:outline-none"
			>
				<span>Learn More</span>
				<div
					class="flex items-center justify-center w-4 h-4 transition duration-100 ease-in-out transform translate-x-0 rounded-full group-hover:translate-x-2"
				>
					<RightArrowIcon class="w-4 h-4" />
				</div>
			</a>
		</div>
	</div>
</div>
