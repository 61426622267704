<script lang="ts">
	import Button from '../buttons/Button.svelte'
	import PhoneFrame from './PhoneFrame.svelte'
	import TripScreen from './TripScreen.svelte'
	import { track } from '$lib/utils/track'
	import { goto } from '$app/navigation'

	function exploreGuidesClick() {
		track('Explore Guides Clicked')
		goto('/explore')
	}
</script>

<div class="overflow-hidden pt-16 pb-24 sm:py-24 lg:py-32 xl:py-36" data-testid="JoinCta">
	<div class="mx-auto max-w-7xl px-4 sm:px-6">
		<div class="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
			<div class="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 xl:col-span-6">
				<div
					class="-mx-4 h-[448px] px-4 sm:px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32"
				>
					<PhoneFrame>
						<TripScreen />
					</PhoneFrame>
				</div>
			</div>
			<div class="relative z-10 max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-32 xl:col-span-6">
				<h2
					class="text-3xl font-extrabold tracking-tight text-black sm:text-4xl lg:text-5xl sm:leading-normal my-2 lg:my-4"
				>
					Find Your Next Adventure
				</h2>

				<div class="space-y-6">
					<p class="max-w-2xl leading-normal">
						Gain inspiration from knowledgeable travelers and design the trips of your dreams all in
						one beautiful, integrated space.
					</p>

					<div
						class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-start mt-8 w-full"
					>
						<Button
							theme="dark"
							priority="secondary"
							class="w-full sm:w-auto"
							size="md"
							onclick={exploreGuidesClick}>Explore Guides</Button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
