<script lang="ts">
	import MainHero from '$lib/components/marketing/MainHero.svelte'
	import PressLogos from '$lib/components/marketing/PressLogos.svelte'
	import SuperPowers from '$lib/components/marketing/SuperPowers.svelte'
	import SvelteSeo from 'svelte-seo'
	import type { PageServerData } from './$types'
	import JoinCta from '$src/lib/components/marketing/JoinCTA.svelte'
	import SocialProof from '$src/lib/components/marketing/SocialProof.svelte'
	import Guides from '$lib/components/marketing/Guides.svelte'
	import Trips from '$lib/components/marketing/Trips.svelte'

	interface Props {
		// import PopularVisas from '$lib/components/landing/PopularVisas.svelte'
		data: PageServerData
	}

	let { data }: Props = $props()

	let published = $derived(data.tripsData?.exploreTrips)

	// $: tipsData = data.tipsData
	let title = $derived(data?.title)
	let description = $derived(data?.description)

	let images = $derived([
		{
			url: `https://airheart.com/airheart-social.png`,
			width: 1200,
			height: 600,
			alt: title,
		},
	])
</script>

<SvelteSeo
	twitter={{
		description,
		title,
		image: `https://airheart.com/airheart-social.png`,
		imageAlt: title,
		site: '@airheartinc',
		card: 'summary_large_image',
	}}
	openGraph={{
		title,
		images,
		description,
	}}
/>

<div class="col-span-12 bg-white">
	<MainHero />
	<!-- <MainMission /> -->
	<Trips {published} />
	<SuperPowers />
	<SocialProof />
	<PressLogos />
	<Guides />
	<JoinCta />
</div>
