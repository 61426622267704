<script lang="ts">
	import { type ExploreTripsQuery } from '$lib/queries/generated/QueryListings'
	import { track } from '$lib/utils/track'
	import { navigating, page } from '$app/state'
	import { goto, preloadData } from '$app/navigation'
	import ExploreTripCard from '../itineraries/ExploreTripCard.svelte'

	interface Props {
		published?: ExploreTripsQuery['exploreTrips']
	}

	let { published = [] }: Props = $props()
	let nextIntentID = $state(null)

	function buildTripURL(tripID: string) {
		let nextURL = new URL(`/explore/${tripID}`, page?.url)
		return nextURL
	}

	function handleClickTrip(listingId: string) {
		track('Explore Trip', { listingId: listingId })
		let nextURL = buildTripURL(listingId)

		setTimeout(() => {
			if (navigating) {
				nextIntentID = listingId
			}
		}, 100)

		goto(nextURL.toString())
	}

	function preloadListingPage(tripID: string) {
		let nextURL = buildTripURL(tripID)
		return preloadData(nextURL.toString())
	}
</script>

<div class="bg-white pt-12 pb-40 lg:pt-24 lg:pb-94">
	<div class="max-w-7xl mx-auto relative isolate px-4 lg:px-16">
		<div class="mt-4 grid grid-cols-1 gap-8 md:grid-cols-4">
			{#each published as listing}
				<ExploreTripCard
					vertical={true}
					trip={listing?.currentTrip}
					fetching={nextIntentID === listing?.currentTrip?.id}
					onclick={() => handleClickTrip(listing?.id)}
					onmouseenter={() => preloadListingPage(listing?.id)}
				/>
			{/each}
		</div>
	</div>
</div>
