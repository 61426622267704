<script lang="ts">
	import Button from '$lib/components/buttons/Button.svelte'
	import { track } from '$lib/utils/track'
	import { goto } from '$app/navigation'

	function exploreGuidesClick() {
		track('Explore Guides Clicked')
		goto('/explore')
	}
</script>

<main class="relative isolate pt-6 max-w-screen-xl mx-4 sm:mx-auto mt-8 sm:mt-12 md:mt-16 xl:mt-20" data-testid="MainHero">
	<div class="flex flex-col items-center justify-center text-center px-4 sm:px-6">
		<h1
			class="mt-1 text-3xl font-extrabold leading-10 tracking-tight text-black sm:leading-none sm:text-4xl md:text-5xl"
		>
			Shop Digital Travel Guides<br class="inline" />
		</h1>
		<div class="flex flex-col items-center mt-5 sm:max-w-xl sm:mx-auto sm:text-center">
			<h2 class="text-lg text-pretty font-medium tracking-tight text-black">
				Discover unique destinations, hidden gems, and essential tips—all tried and tested for real
				journeys.
			</h2>
			<div
				class="flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 sm:gap-x-4 justify-center mt-8 w-full"
			>
				<Button
					theme="dark"
					priority="secondary"
					class="w-full sm:w-auto"
					size="md"
					onclick={exploreGuidesClick}>Explore Guides</Button
				>
			</div>

			<div class="text-xs text-brand-gray-4 mt-3">
				Instant signup. No credit card required.
				<a href="/legal/terms" class="underline">Terms of Service</a> and
				<a href="/legal/privacy" class="underline">Privacy Policy</a>.
			</div>
		</div>
	</div>
</main>
